import React from 'react';
import { MainLayout } from '../../components/Layout';
import PartnersList from '../../components/Partners/PartnersList';
import { Link, withPrefix } from 'gatsby';
import imgBg from '../../assets/img/home/bg-wave.png';
import imgMobile from '../../assets/img/partners/mobile-petites-annonces.png';
import ReferencesSection from '../../components/Partners/ReferencesSection';
import iconMairie17 from '../../assets/img/references/logo_mairie17.png';
//import iconPichetImmobilier from "../../assets/img/references/pichet-immobilier.png"
import icon1001ViesHabitat from '../../assets/img/references/1001-vies-habitat.png';

export default () => {
  const referenceList = [
    {
      icon: iconMairie17,
      content:
        'Alacaza accompagne le développement social des territoires sur lesquels leur solution se déploie. En effet, l’outil permet aux habitants, d’un même immeuble et d’un même quartier, de fluidifier et d’enrichir leurs relations grâce à l’intégration des commerçants et des associations dans une application unifiée.',
    },
    // {
    //   "icon": iconPichetImmobilier,
    //   "content": ""
    // },
    {
      icon: icon1001ViesHabitat,
      content: '',
    },
  ];

  return (
    <MainLayout
      siteTitle={`Commercants locaux, associations, etc. - Découvrez nos offres`}
      siteDescription={`Découvrez nos offres destinées aux commercants locaux, associations, etc.`}
    >
      <PartnersList selectedPartner="autres" />

      <div
        className="bg-wave"
        style={{ backgroundImage: `url(${withPrefix(imgBg)})` }}
      >
        <section className="container mt-5">
          <div className="row">
            <div className="col-6 text-align-center hidden-sm">
              <img
                src={imgMobile}
                alt="Syndic de copropriété"
                className="max-width-400-lg"
              />
            </div>

            <div className="col-4">
              <div className="pl-5 pr-5">
                <div className="roboto font-size-xl bold pt-10">
                  Commerçants locaux
                </div>
                <div className="text-color-purple-grey pt-5">
                  Les commerçants du quartier peuvent être ajoutés à notre
                  application afin de gagner en visibilité et de cibler une
                  clientèle de proximité
                </div>

                <div className="roboto font-size-xl bold pt-10 pt-5-md">
                  Associations
                </div>
                <div className="text-color-purple-grey pt-5">
                  Les associations du quartier sont essentielles à l'animation
                  de la vie sociale locale. Alacaza souhaite les mettre en
                  valeur auprès des résidents afin de les informer sur leurs
                  activités
                </div>

                <div className="ml-auto mr-auto pt-15 text-align-center hidden-sm">
                  <Link
                    to="/contact/"
                    state={{ contactType: 'partner', contactSubType: 'autres' }}
                    className="btn"
                  >
                    Nous contacter
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row hide show-md">
            <div className="col-12 pt-5 pl-6-md pr-6-md text-align-center">
              <img src={imgMobile} alt="Syndic de copropriété" />
            </div>
          </div>

          <div className="row pb-10 hide show-md">
            <div className="col-12 ml-auto mr-auto text-align-center">
              <Link
                to="/contact/"
                state={{ contactType: 'partner', contactSubType: 'autres' }}
                className="btn"
              >
                Nous contacter
              </Link>
            </div>
          </div>
        </section>
      </div>

      <ReferencesSection referenceList={referenceList} />
    </MainLayout>
  );
};
