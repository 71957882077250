import React from 'react';

import SwiperCore, { Navigation, Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

import iconNext from '../../assets/img/icons/next.svg';
import iconBack from '../../assets/img/icons/back.svg';

SwiperCore.use([Navigation, Pagination]);
export const ReferencesSection = (props) => {
  const { referenceList } = props;

  return (
    <section className="container mt-15 mb-20 section-references">
      <div className="row">
        <div className="col-12">
          <div className="roboto bold font-size-xl text-align-center">
            Ils nous font confiance
          </div>
        </div>
        <div className="col-12 flex justify-content-center">
          <div className="col-6 references-list-container text-align-center pl-3-md pr-3-md relative">
            <Swiper
              slidesPerView={1}
              spaceBetween={20}
              pagination={{ el: '.steps-list', clickable: true }}
              navigation={{ prevEl: '.left', nextEl: '.right' }}
              loop
            >
              {referenceList.map((reference, index) => (
                <SwiperSlide key={`reference-${index}`}>
                  <div
                    key={'reference-item-' + index}
                    className="reference-container mt-3 ml-auto mr-auto"
                  >
                    <div className="reference-icon pt-5">
                      <img
                        src={reference.icon}
                        alt="Alacaza - Ils parlents de nous"
                      />
                    </div>
                    {/* {reference.content ? <div className="text-color-purple-grey pt-5 pr-4 pl-4 pt-4">{reference.content}</div> : null} */}
                    <div className="steps-list pt-4"></div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="left absolute hide-md p-4">
              <img src={iconBack} alt="Back" />
              <img src={iconBack} alt="Back" />
            </div>
            <div className="right absolute hide-md p-4">
              <img src={iconNext} alt="Next" />
              <img src={iconNext} alt="Next" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReferencesSection;
